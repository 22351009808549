import {
  DateRange,
  Business,
  Error,
  Today,
  Edit,
  EventBusy,
  Visibility,
  FilterNone,
  Person,
  Event,
  NotificationsOff,
  NotificationsActive,
  NotificationImportant,
  Autorenew,
  LocalOffer,
  Info,
  FilterList,
  Close,
  Group
} from '@material-ui/icons'
import { TextField, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React, { useState } from 'react'
import update from 'immutability-helper'
import Button from "components/CustomButtons/Button";

import { encryptData } from 'assets/jss/EncriptDecrypt/EncryptDecrypt'

import Loading from 'components/Loading/Loading';
import ToggleItemList from 'components/ToggleItemList/ToggleItemList';

const ListEventEntries = ({ events, updateEventEntries, buttons }) => {

  const [loading, setLoading] = useState(false)

  const campuses = Array.from(new Set(events.map(e => e.campus)))
  const createdBy = Array.from(new Set(events.map(e => e.createdBy)))
  const departments = Array.from(new Set(events.map(e => e.createdByDepartment)))
  const tags = Array.from(new Set(events.map(e => e.tag)))
  const orderBy = ['Starts', 'Name']

  const listEvents = events.map(e => {
    let searchString = `${e.name} ${e.createdByDepartment} ${e.campus} ${e.createdBy} ${e.tag} ${e.timeFrame}`
    return {
      ...e,
      searchString: searchString.toLowerCase()
    }
  })

  const emptySearch = {
    search: '',
    campuses: [],
    createdBy: [],
    tags: [],
    orderBy: orderBy[0],
    department: []
  }

  const [search, setSearch] = useState(emptySearch)

  const [toggleFilter, setToggleFilter] = useState(false)

  const sortEvents = (a, b) => {
    if (search.orderBy === 'Starts') {
      if (a.starts > b.starts) return -1
      if (a.starts < b.starts) return 1
      return 0
    }

    if (search.orderBy === 'Name') {
      if (a.name > b.name) return -1
      if (a.name < b.name) return 1
      return 0
    }
  }

  const filteredEvents = listEvents.sort(sortEvents).filter(e => {
    let searchText = search.search.toLowerCase()
    let matches = true

    if (search.search !== '') {
      matches = matches && e.searchString.includes(searchText)
    }

    if (search.campuses.length > 0) {
      matches = matches && search.campuses.indexOf(e.campus) !== -1
    }

    if (search.createdBy.length > 0) {
      matches = matches && search.createdBy.indexOf(e.createdBy) !== -1
    }

    if (search.tags.length > 0) {
      matches = matches && search.tags.indexOf(e.tag) !== -1
    }

    if (search.department.length > 0) {
      matches = matches && search.department.indexOf(e.createdByDepartment) !== -1
    }

    return matches

  })

  const updateSearch = (updateData) => {
    setSearch(prev => {
      return update(prev, updateData)
    })
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: '5px' }}>
        {toggleFilter ?
          <Button
            style={{ margin: '5px 0px' }}
            justIcon
            color="danger"
            title='Close'
            onClick={() => setToggleFilter(false)}
          >
            <Close />
          </Button>
          :
          <Button
            style={{ margin: '5px 0px' }}
            justIcon
            color="primary"
            title='Filter'
            onClick={() => setToggleFilter(true)}
          >
            <FilterList />
          </Button>
        }

        <div
          style={{
            minWidth: toggleFilter ? '0px' : '200px',
            maxWidth: toggleFilter ? '0px' : '300px',
            opacity: toggleFilter ? 0 : 1,
            flex: 1,
            margin: '5px 0px 5px 5px',
            transition: 'all 1000ms, opacity 500ms cubic-bezier(0.4, 0, 1, 1)'
          }}>
          <TextField
            label='Search'
            fullWidth
            variant='outlined'
            value={search.search}
            onChange={(e) => updateSearch({ search: { $set: e.target.value } })}
          />
        </div>
      </div>
      <div
        style={{
          opacity: !toggleFilter ? 0 : 1,
          minHeight: !toggleFilter ? '0px' : '125px',
          maxHeight: !toggleFilter ? '0px' : '800px',
          overflow: 'auto',
          transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92), opacity 100ms cubic-bezier(0.4, 0, 1, 1)'
        }}
      >
        <div
          style={{
            borderRadius: "4px",
            background: "#fff",
            padding: "5px 0px",
            border: "1px solid #d4d4d4",
            alignItems: 'center',
            width: '100%',
            margin: '0px 0px 10px',
          }}
        >
          <div style={{ margin: '5px' }}>
            <TextField
              style={{ borderRadius: "4px", backgroundColor: '#fff' }}
              label='Search'
              fullWidth
              variant='outlined'
              value={search.search}
              onChange={(e) => updateSearch({ search: { $set: e.target.value } })}
            />
          </div>

          <div
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <div
              style={{
                flex: 1,
                margin: '5px',
                minWidth: '200px',
                maxWidth: '300px',
              }}>
              <ToggleItemList
                items={createdBy}
                selectedItems={search.createdBy}
                label={'Created By'}
                updateItemList={(updateData) => updateSearch({ createdBy: updateData })}
              />
            </div>

            <div
              style={{
                flex: 1,
                margin: '5px',
                minWidth: '200px',
                maxWidth: '300px',
              }}>
              <ToggleItemList
                items={departments}
                selectedItems={search.department}
                label={'Department'}
                updateItemList={(updateData) => updateSearch({ department: updateData })}
              />
            </div>

            <div
              style={{
                flex: 1,
                margin: '5px',
                minWidth: '200px',
                maxWidth: '300px',
              }}>
              <ToggleItemList
                items={campuses}
                selectedItems={search.campuses}
                label={'Campus'}
                updateItemList={(updateData) => updateSearch({ campuses: updateData })}
              />
            </div>

            <div
              style={{
                flex: 1,
                margin: '5px',
                minWidth: '200px',
                maxWidth: '300px',
              }}>
              <ToggleItemList
                items={tags}
                selectedItems={search.tags}
                label={'Tags'}
                updateItemList={(updateData) => updateSearch({ tags: updateData })}
              />
            </div>

          </div>
        </div>
      </div>
      <p
        style={{
          fontWeight: 'bold',
          borderBottom: '1px solid #eee',
          borderTop: '1px solid #eee',
          fontSize: '24px',
          padding: '3px 0px'
        }}
      >
        Event Entries
      </p>
      {loading ? <Loading color='blue' /> :
        <>
          {filteredEvents.map((event, index) => {
            return (
              <EventEntry
                key={event.id}
                event={event}
                index={index}
                buttons={buttons}
                setLoading={setLoading}
              />
            )
          })}
          {(filteredEvents.length === 0) && (
            <p>No Events.</p>
          )}
        </>}
    </React.Fragment>
  )
}

const EventEntry = ({ event, index, buttons, setLoading }) => {
  return (
    <div
      style={{
        background: index % 2 === 0 ? '#fff' : "#f2f2f2",
        padding: "5px",
        borderBottom: "1px solid #eee",
        width: '100%',
        display: 'flex',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p style={{ margin: '0px 0px 0px 4px', fontWeight: 'bold', fontSize: '20px' }}>
            {event.name}
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Created By'
          >
            <Person style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.createdBy}</span>
          </div>

          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Campus'
          >
            <Business style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.campus}</span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Department'
          >
            <Group style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.createdByDepartment}</span>
          </div>
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Created By'
          >
            <LocalOffer style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.tag}</span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='When'

          >
            <Event style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.timeFrame}</span>
          </div>

          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Event Type'
          >
            <Info style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.bigEvent ? 'Big Event' : 'Regular Event'}</span>
          </div>
        </div>

      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          maxWidth: '90px',
          justifyContent: 'right',
        }}
      >
        <Button
          style={{ margin: '2px' }}
          justIcon
          color="primary"
          title='View'
        // onClick={() => viewClickHandler(item.id)}
        >
          <Visibility />
        </Button>
        <Button
          style={{ margin: '2px' }}
          justIcon
          color="primary"
          title='Edit'
          onClick={() => {
            let search = encryptData({
              returnLocation: window.location.href,
            })
            window.location.href = `/portal/events/user/edit/${event.eventID}?${search}`
          }}
        >
          <Edit />
        </Button>
      </div>
    </div>
  )
}

export default ListEventEntries