import Loading from 'components/Loading/Loading'
import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import CustomTabs from "components/CustomTabs/CustomTabs";
import {
  myTeamsEvents,
  getMyTeamsEvents
} from 'state/events';
import { userAtom } from 'state/auth';
import ListEvents from './EventTabs/ListEvents';

const MyEventsComp = (props) => {
  const [teamEvents, setTeamEvents] = useRecoilState(myTeamsEvents)
  const user = useRecoilValue(userAtom)
  const userEvents = teamEvents.filter(e => e.createdBy === user.googleID)

  const needsRenewal = teamEvents.filter(e => e.needsRenewal)
  const hasConflicts = teamEvents.filter(e => e.eventConflicts > 0)

  const updateEvents = async () => {
    let e = await getMyTeamsEvents()
    setTeamEvents(e)
    return
  }

  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: "My Events",
      tabContent: <ListEvents events={userEvents.filter(e => e.upcomingEventEntries.length > 0)} updateEvents={updateEvents} />,
    },
    {
      tabName: "Team Events",
      tabContent: <ListEvents events={teamEvents.filter(e => e.upcomingEventEntries.length > 0)} updateEvents={updateEvents} />,
    },
    {
      tabName: `Needs Renewal (${needsRenewal.length})`,
      tabContent: <ListEvents events={needsRenewal} updateEvents={updateEvents} />,
    },
    {
      tabName: `Has Conflicts (${hasConflicts.length})`,
      tabContent: <ListEvents events={hasConflicts} updateEvents={updateEvents} />,
    },
    {
      tabName: "Ended Events",
      tabContent: <ListEvents events={teamEvents.filter(e => e.upcomingEventEntries.length == 0)} updateEvents={updateEvents} />,
    },
  ];

  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const MyEventLoading = (props) => {

  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: "My Events",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Team Events",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Needs Renewal",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Has Conflicts",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Ended Events",
      tabContent: <Loading color="blue" />,
    },
  ];

  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const MyEvents = (props) => {
  return (
    <React.Suspense fallback={<MyEventLoading {...props} />}>
      <MyEventsComp {...props} />
    </React.Suspense>
  )
}

export default MyEvents