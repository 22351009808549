import React, { useContext, useEffect } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

//import routes from "routes.js";

//import { routesAtom } from "routes";
//import { useRecoilState } from "recoil";
import useRoutes from "routes";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import SingleUser from "../views/StaffCheckin/SingleUser";

import { loggedInAtom, validCredToken } from "../state/auth";
import { dashboardNavAtom } from "../state/dashboard";
import Loading from "components/Loading/Loading";

import { useRecoilValue } from "recoil";
import Settings from "views/Settings/Settings";
import PurchaseOrderSubmit from "views/PurchaseOrders/PurchaseOrderSubmit";
//import PurchaseOrderView from "views/PurchaseOrders/PurchaseOrderView";
import PurchaseOrderEdit from "views/PurchaseOrders/PurchaseOrderEdit";
import AdminPurchaseOrderEdit from "views/PurchaseOrders/Admin/AdminPurchaseOderEdit";
import PurchaseOrderPDF from "views/PurchaseOrders/PurchaseOrderPDF";
import AdminPurchaseOrderPDF from "views/PurchaseOrders/Admin/AdminPurchaseOrderPDF";
import HBABFormPDF from "views/HitByABus/HBABFormPDF";
import CreateEditContractor from "views/ContractorInvoices/Contractors/CreateEditContractor";
import ViewContractor from "views/ContractorInvoices/Contractors/ViewContractor";
import FormToEmail from "views/OnlineForms/FormToEmail";
import AddEditContractorDepartment from "views/ContractorInvoices/Contractors/AddEditContractorDepartment";
import CreateEditInvoice from "views/ContractorInvoices/Invoices/CreateEditInvoice";
import ViewTicket from "views/TicketSystem/ViewTicket";
import EditEvent from "views/EventsCalendar/EditEvent";
import RenewEvent from "views/EventsCalendar/RenewEvent";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <DashboardComp {...props} />
    </React.Suspense>
  )
}


function DashboardComp(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(
    localStorage.getItem("miniActive") === "true"
  );

  const isLoggedIn = useRecoilValue(loggedInAtom);
  const routes = useRecoilValue(dashboardNavAtom);
  const logo = require("assets/img/logo.svg").default;
  const image = require("assets/img/sidebar-2.jpg").default;
  const color = "blue";
  const bgColor = "black";

  /* useEffect(() => {
    if (!isLoggedIn) {
      window.location = "/auth";
    }
  }, []); */

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (!isLoggedIn) {
      window.location = "/auth";
    }
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  /* const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg").default);
        break;
      default:
        setLogo(require("assets/img/logo-white.svg").default);
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  }; */

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoute = () => {
    return window.location.pathname !== "/portal/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    if (!Array.isArray(routes)) return null;
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = (routes) => {
    if (!Array.isArray(routes)) return null;
    return routes.map((prop, key) => {
      if (!isLoggedIn) {
        return <Redirect to="/auth" />;
      }

      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/portal") {
        /* let ha = hasAccess(
          prop.accessLevel.groupName,
          prop.accessLevel.groupRole
        ); */

        //if (ha) {

        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );

        // } else {
        //   return null;
        // }
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
    localStorage.setItem("miniActive", !miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    validCredToken()
  }, [window.location.pathname])

  useEffect(() => {
    validCredToken()
  }, [])

  return (
    isLoggedIn && (
      <div className={classes.wrapper}>
        <React.Suspense fallback={<></>}>
          <Sidebar
            routes={routes}
            logoText={"Staff Portal"}
            logo={logo}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            color={color}
            bgColor={bgColor}
            miniActive={miniActive}
            {...rest}
          />
        </React.Suspense>
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  <Route
                    exact
                    path="/portal/staffcheckin/user/:user"
                    component={SingleUser}
                  />

                  <Route
                    exact
                    path="/portal/purchase-orders/view/:id"
                    component={PurchaseOrderPDF}
                  />

                  <Route
                    exact
                    path="/portal/purchase-orders/admin/view/:id"
                    component={AdminPurchaseOrderPDF}
                  />

                  <Route
                    exact
                    path="/portal/MyProfile/HBAB/:type/:id"
                    component={HBABFormPDF}
                  />

                  <Route
                    exact
                    path="/portal/ticketSystem/view/:userType/:id"
                    component={ViewTicket}
                  />

                  <Route
                    exact
                    path="/portal/events/:userType/edit/:id"
                    component={EditEvent}
                  />

                  <Route
                    exact
                    path="/portal/events/:userType/renew/:id"
                    component={RenewEvent}
                  />

                  {/* <Route
                    exact
                    path="/portal/contractors/addEdit/:userType/:id"
                    component={CreateEditContractor}
                  />

                  <Route
                    exact
                    path="/portal/contractors/invoice/edit/:id"
                    component={CreateEditInvoice}
                  />

                  <Route
                    exact
                    path="/portal/contractors/manageDepartment/:userType/:id"
                    component={AddEditContractorDepartment}
                  />

                  <Route
                    exact
                    path="/portal/contractors/view/:userType/:id"
                    component={ViewContractor}
                  /> */}

                  <Route
                    exact
                    path="/portal/purchase-orders/admin/edit/:id"
                    component={AdminPurchaseOrderEdit}
                  />

                  <Route
                    exact
                    path="/portal/purchase-orders/edit/:id"
                    component={PurchaseOrderEdit}
                  />

                  <Route
                    exact
                    path="/portal/onlineforms/:formType"
                    component={FormToEmail}
                  />

                  <Route
                    exact
                    path="/portal/purchase-orders/submit"
                    component={PurchaseOrderSubmit}
                  />
                  <Route exact path="/portal/settings" component={Settings} />
                  {getRoutes(routes)}

                  <Redirect from="/portal" to="/portal/dashboard" />
                </Switch>
              </div>
            </div>
          ) : (
            <></>
          )}
          {getRoute() ? <Footer fluid /> : null}
          {!isLoggedIn ? <Redirect to="/auth" /> : ""}
        </div>
      </div>
    )
  );
}
