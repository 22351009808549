import { atom, selector, useRecoilValue } from "recoil";
import {
  groupsAtom,
  userAtom
} from "./auth";
import { publishedPagesAtom } from "./trainingModule";

// Components
import Dashboard from "views/Dashboard/Dashboard.js";
import StaffCheckinPage from "views/StaffCheckin/StaffCheckin";
import OnlineForms from "views/OnlineForms/OnlineForms";
import Portal from "views/Portal/Portal";
import StaffDirectory from "views/StaffDirectory/StaffDirectory";
import Links from "views/Links/Links";
import PackagingCalculator from "views/PackagingCalculator/PackagingCalculator";

// @material-ui/icons
import Alarm from "@material-ui/icons/Alarm";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EditDashboard from "views/Newsfeed/EditDashboard";
import Link from "@material-ui/icons/Link";
import ODLinks from "views/Links/ODLinks";
import FolderShared from "@material-ui/icons/FolderShared";
import Computer from "@material-ui/icons/Computer";
import ComputerIcon from '@material-ui/icons/Computer';
import Language from "@material-ui/icons/Language";
import InboxIcon from '@material-ui/icons/Inbox';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import RateReviewIcon from '@material-ui/icons/RateReview';
import PurchaseOrderSubmit from "views/PurchaseOrders/PurchaseOrderSubmit";
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ApartmentIcon from '@material-ui/icons/Apartment';
//import PurchaseOrderView from "views/PurchaseOrders/PurchaseOrderView";
import SubmittedToMe from "views/PurchaseOrders/SubmittedToMe";
import SubmittedByMe from "views/PurchaseOrders/SubmittedByMe";
import SubmittedToReview from "views/PurchaseOrders/SubmittedToReview";
import { Assessment, AttachMoney, BubbleChart, CalendarToday, ComputerOutlined, Create, Description, Event, ListAlt, MeetingRoom, MenuBook, PermContactCalendar } from "@material-ui/icons";

import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
import CallMissedIcon from "@material-ui/icons/CallMissed";
import AdminView from "views/PurchaseOrders/Admin/AdminView";
import ReceiptIcon from '@material-ui/icons/Receipt';

import SendIcon from "@material-ui/icons/Send";
import WorkIcon from '@material-ui/icons/Work';
import GroupIcon from '@material-ui/icons/Group';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import PurchaseOrderSubmitAdmin from "views/PurchaseOrders/PurchaseOrderSubmitAdmin";
import PastoralCare from "views/PastoralCare/PastoralCare";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SubmittedByTeam from "views/PurchaseOrders/SubmittedByTeam";
import TrainingCreation from "views/TrainingModule/TrainingCreation";
import TrainingCreationDisplay from "views/TrainingModule/TrainingCreationDisplay";
import HitByABusForm from "views/HitByABus/HitByABusForm";
import MyMinistry from "views/HitByABus/MyMinistry";
import MyTeam from "views/HitByABus/MyTeam";
import HitByABusAdmin from "views/HitByABus/HitByABusAdmin";
import POBudgetsAndCodes from "views/PurchaseOrders/POBudgetsAndCodes/POBudgetsAndCodes";
import AdminViewContractors from "views/ContractorInvoices/Contractors/AdminViewContractors";
import ListAdminUtils from "views/Admin/ListAdminUtils";
import ExpenseReport from "views/PurchaseOrders/ExpenseReport/ExpenseReport";
import ReviewerViewMyDepartments from "views/ContractorInvoices/Contractors/ReviewerViewMyDepartments";
import ViewerViewDepartments from "views/ContractorInvoices/Contractors/ViewerViewDepartments";
import CreateEditInvoice from "views/ContractorInvoices/Invoices/CreateEditInvoice";
import CreateEditTicket from 'views/TicketSystem/CreateEditTicket'

import { isDepartmentOwnerOrAdminAtom, inADepartmentAtom } from "./ticketSystem";
import DepartmentManager from "views/TicketSystem/DepartmentManager";
import DepartmentTickets from "views/TicketSystem/DepartmentTickets";
import MyTickets from "views/TicketSystem/MyTickets";
import DepartmentAnalytics from "views/TicketSystem/DepartmentAnalytics";
import RoomManager from "views/EventsCalendar/RoomManager";
import EventCalanderManager from "views/EventsCalendar/EventCalanderManager";
import CreateEditEvent from "views/EventsCalendar/CreateEditEvent";
import MyEvents from "views/EventsCalendar/MyEvents";

export const zipcodeAtom = atom({
  key: "zipcode",
  default: null,
});

export const newWidgetChoiceAtom = atom({
  key: "newWidgetChoice",
  default: "CalendarWidget",
});

export const getNewChoice = selector({
  key: "getNewChoice",
  get: ({ get }) => {
    const newChoice = get(newWidgetChoiceAtom);

    return newChoice;
  },
});

export const dashboardNavAtom = atom({
  key: "dashboardNav",
  default: selector({
    key: "dashboardNavSelector",
    get: ({ get }) => {
      const userGroups = get(groupsAtom);
      const listOfPages = get(publishedPagesAtom);
      const user = get(userAtom)
      const deptOwner = get(isDepartmentOwnerOrAdminAtom)
      const inDept = get(inADepartmentAtom)

      if (user === null) {
        location.href = '/auth'
      }

      const hasAccess = (groupName, groupRole) => {
        if (!userGroups) return false;
        //const userGroups = JSON.parse(userGroups);
        if (
          userGroups.find(
            (item) =>
              item.name === groupName &&
              item.role.toLowerCase() === groupRole.toLowerCase()
          )
        ) {
          return true;
        }

        return false;
      };

      const hasGroup = (groupName) => {
        if (!userGroups) return false;
        //const userGroups = JSON.parse(userGroups);
        if (
          userGroups.find(
            (item) =>
              item.name === groupName
          )
        ) {
          return true;
        }

        return false;
      };

      const hasExpenseReportAccess = () => {
        if (!userGroups) return false;
        if (
          userGroups.find(
            (item) =>
              item.name.includes('internal-er-') &&
              item.role === 'OWNER'
          )
        ) {
          return true;
        }

        return false;
      }

      const checkinOwner = () => {
        if (!userGroups) return false;
        //const userGroups = JSON.parse(userGroups);
        if (
          userGroups.find(
            (item) =>
              item.name.includes('internal-cc-') &&
              item.role === 'OWNER'
          )
        ) {
          return true;
        }

        return false;
      };

      const hasAdminGroupAccess = (groupName) => {
        if (!userGroups) return false;

        //const userGroups = JSON.parse(userGroups);

        /**console.log(
          `find function(${groupName}, ${groupRole})`,
          userGroups.find(
            (item) =>
              item.name === groupName &&
              item.role.toLowerCase() === groupRole.toLowerCase()
          )
        );*/

        if (
          userGroups.find(
            (item) => item.adminGroup === groupName //&&
            //item.role.toLowerCase() === groupRole.toLowerCase()
          )
        ) {
          return true;
        }

        return false;
      };

      const hasAdminAccess = () => {
        if (!userGroups) return false;

        //const userGroups = JSON.parse(userGroups);

        if (userGroups.find((item) => item.name === "admin")) {
          return true;
        }

        return false;
      };

      const getAdminGroups = () => {
        if (!userGroups) return [];
        //const userGroups = JSON.parse(userGroups);

        let newGroups = userGroups.filter(
          (item) => item.adminGroup !== undefined
        );

        let returnGroups = [];

        newGroups.map((item) => {
          let returnObj = null;

          switch (item.adminGroup) {
            case "internal-admin-nf@sagebrush.church":
              returnObj = {
                path: "/newsfeed",
                name: "Newsfeed",
                icon: AssignmentIcon,
                component: Newsfeed,
                layout: "/portal",
              };
              break;
          }

          if (returnObj) {
            returnGroups.push(returnObj);
          }
        });

        return returnGroups;
      };

      let dashRoutes = [
        {
          path: "/dashboard",
          name: "Dashboard",
          icon: DashboardIcon,
          component: Dashboard,
          layout: "/portal",
        },
        {
          path: "/employeeResources",
          name: "Employee Resources",
          icon: MenuBook,
          component: ODLinks,
          layout: "/portal",
        },
        {
          path: "/links",
          name: "Links",
          icon: Link,
          component: Links,
          layout: "/portal",
        },
        {
          path: "/onlineforms",
          name: "Online Forms",
          icon: AssignmentIcon,
          component: OnlineForms,
          layout: "/portal",
        },
      ];

      if (hasAccess("checkin", "owner") || hasAccess("checkin", "member")) {
        dashRoutes.push({
          path: "/MyProfile",
          name: "My Profile",
          icon: AccountBoxIcon,
          layout: "/portal",
          collapse: true,
          state: 'MyProfile',
          views: [
            {
              path: `/staffcheckin/user/${user.email}`,
              name: "Check-In Records",
              mini: <ScheduleIcon />,
              layout: "/portal",
            },
            {
              path: "/MyProfile/Roles",
              name: "HBAB",
              mini: <AssignmentIcon />,
              component: HitByABusForm,
              layout: "/portal",
            },
          ],
        })

        dashRoutes.push({
          path: "/directory",
          name: "Staff Directory",
          icon: FolderShared,
          component: StaffDirectory,
          layout: "/portal",
        })
        dashRoutes.push({
          path: "/purchase-orders",
          name: "Purchase Orders",
          icon: AttachMoney,
          layout: "/portal",
          collapse: true,
          state: 'PO',
          views: [
            {
              path: "/purchase-orders/create",
              name: "Create New",
              mini: <Create />,
              component: PurchaseOrderSubmit,
              layout: "/portal",
            },
            {
              path: "/purchase-orders/submitted-by-me",
              name: "Submitted By Me",
              mini: <SendIcon />, // HERE
              component: SubmittedByMe,
              layout: "/portal",
              reloadableLink: true,
            }
          ],
        })
      }

      dashRoutes.push({
        path: "/ticketSystem",
        name: "Ticket System",
        icon: Description,
        layout: "/portal",
        collapse: true,
        state: 'ticket',
        views: [
          {
            path: "/ticketSystem/create",
            name: "New Ticket",
            mini: <Create />,
            component: CreateEditTicket,
            layout: "/portal",
          }
        ],
      })

      const addTicketSystemDropdownView = (view) => {
        let index = dashRoutes.findIndex(item => item.name === 'Ticket System')
        dashRoutes[index].views.push(view)
      }

      if (deptOwner) {
        addTicketSystemDropdownView({
          path: "/ticketSystem/manageDepartments",
          name: "Manage Departments",
          mini: <ApartmentIcon />,
          component: DepartmentManager,
          layout: "/portal",
        })

        addTicketSystemDropdownView({
          path: "/ticketSystem/departmentAnalytics",
          name: "Department Analytics",
          mini: <BubbleChart />,
          component: DepartmentAnalytics,
          layout: "/portal",
        })
      }

      if (inDept || deptOwner) {
        addTicketSystemDropdownView({
          path: "/ticketSystem/departmentTickets",
          name: "Department Tickets",
          mini: <Description />,
          component: DepartmentTickets,
          layout: "/portal",
        })
      }

      addTicketSystemDropdownView({
        path: "/ticketSystem/myTickets",
        name: "My Tickets",
        mini: <Description />,
        component: MyTickets,
        layout: "/portal",
      })

      const addEventsDropdownView = (view) => {
        let index = dashRoutes.findIndex(item => item.name === 'Events')
        if (index === -1) {
          dashRoutes.push({
            path: "/events",
            name: "Events",
            icon: Event,
            layout: "/portal",
            collapse: true,
            state: 'Events',
            views: [view],
          })
        } else {
          dashRoutes[index].views.push(view)
        }
      }

      // if (hasGroup('internal-room-manager@sagebrush.church')) {
      //   addEventsDropdownView({
      //     path: "/events/roomManager",
      //     name: "Room Manager",
      //     icon: MeetingRoom,
      //     component: RoomManager,
      //     layout: "/portal",
      //   })
      // }

      if (hasGroup('internal-calendar-user@sagebrush.church') || hasGroup('internal-calendar-admin@sagebrush.church')) {
        addEventsDropdownView({
          path: "/events/create",
          name: "Create",
          icon: Create,
          component: CreateEditEvent,
          layout: "/portal",
        })

        addEventsDropdownView({
          path: "/events/myEvents",
          name: "My Events",
          icon: CalendarToday,
          component: MyEvents,
          layout: "/portal",
        })
      }

      if (hasGroup('internal-room-manager@sagebrush.church')) {
        addEventsDropdownView({
          path: "/events/resourceManager",
          name: "Resource Manager",
          icon: ListAlt,
          component: RoomManager,
          layout: "/portal",
        })
      }

      if (hasGroup('internal-calendar-admin@sagebrush.church')) {
        addEventsDropdownView({
          path: "/events/eventCalendarManager",
          name: "Event Admin",
          icon: PermContactCalendar,
          component: EventCalanderManager,
          layout: "/portal",
        })
      }

      const addContractorDropdownView = (view) => {
        let index = dashRoutes.findIndex(item => item.name === 'Contractors')
        if (index === -1) {
          dashRoutes.push({
            path: "/contractors",
            name: "Contractors",
            icon: WorkIcon,
            layout: "/portal",
            collapse: true,
            state: 'Contractor',
            views: [view],
          })
        } else {
          dashRoutes[index].views.push(view)
        }
      }

      // if (hasAccess('internal-contractors@sagebrush.church', 'member') || true) {
      //   addContractorDropdownView({
      //     path: "/contractors/invoice/create",
      //     name: "Create New",
      //     mini: <Create />,
      //     component: CreateEditInvoice,
      //     layout: "/portal",
      //   },)
      // }

      // if (hasAccess('internal-contractor-admin@sagebrush.church', 'member')) {
      //   addContractorDropdownView({
      //     path: "/contractors/admin/list",
      //     name: "Admin",
      //     mini: <GroupIcon />,
      //     component: AdminViewContractors,
      //     layout: "/portal",
      //   })
      // }

      // if (hasAccess('internal-contractor-reviewer@sagebrush.church', 'member')) {
      //   addContractorDropdownView({
      //     path: "/contractors/reviewer/list",
      //     name: "My Departments",
      //     mini: <ApartmentIcon />,
      //     component: ReviewerViewMyDepartments,
      //     layout: "/portal",
      //   })
      // }

      // if (hasAccess('internal-contractor-viewer@sagebrush.church', 'member')) {
      //   addContractorDropdownView({
      //     path: "/contractors/viewer/list",
      //     name: "Viewable Departments",
      //     mini: <ApartmentIcon />,
      //     component: ViewerViewDepartments,
      //     layout: "/portal",
      //   })
      // }

      const POIndex = dashRoutes.findIndex(item => item.name === 'Purchase Orders')
      const myProfileIndex = dashRoutes.findIndex(item => item.name === 'My Profile')

      const addUtilityDropdown = () => {
        dashRoutes.push({
          path: "/Util",
          name: "Utilities",
          icon: SettingsApplicationsIcon,
          layout: "/portal",
          collapse: true,
          state: 'Utility',
          views: [],
        },)
      }

      if (listOfPages) {
        listOfPages.forEach(page => {
          let i = dashRoutes.findIndex(item => item.name === page.dropdown)
          if (i === -1) {
            dashRoutes.push({
              path: `/${page.dropdown.replaceAll(' ', '')}`,
              name: page.dropdown,
              icon: AddIcon,
              layout: "/portal",
              collapse: true,
              state: page.dropdown.replaceAll(' ', ''),
              views: [{
                path: `/${page.dropdown.replaceAll(' ', '')}/${page.title.replaceAll(' ', '')}-${page._id}`,
                name: page.title,
                mini: <RemoveIcon />,
                component: TrainingCreationDisplay,
                layout: "/portal",
              }
              ],
            })
          } else {
            dashRoutes[i].views.push({
              path: `/${page.dropdown.replaceAll(' ', '')}/${page.title.replaceAll(' ', '')}-${page._id}`,
              name: page.title,
              mini: <RemoveIcon />,
              component: TrainingCreationDisplay,
              layout: "/portal",
            })
          }
        })
      }

      if (hasAccess("checkin", "owner")) {
        let index = dashRoutes.findIndex(item => item.name === 'Utilities')
        dashRoutes[myProfileIndex].views.push(
          {
            path: "/MyProfile/Ministry",
            name: "Ministry HBAB",
            mini: <AssignmentIcon />,
            component: MyMinistry,
            layout: "/portal",
          },
        )
        dashRoutes[myProfileIndex].views.push(
          {
            path: "/MyProfile/Team",
            name: "Team HBAB",
            mini: <AssignmentIcon />,
            component: MyTeam,
            layout: "/portal",
          },
        )
        if (index === -1) {
          addUtilityDropdown()
          dashRoutes[dashRoutes.length - 1].views.push({
            path: "/Util/staffcheckin",
            name: "Checkin",
            icon: Alarm,
            layout: "/portal",
            component: StaffCheckinPage,
          })
        } else {
          dashRoutes[index].views.push({
            path: "/Util/staffcheckin",
            name: "Checkin",
            icon: Alarm,
            layout: "/portal",
            component: StaffCheckinPage,
          })
        }
      }
      if (hasAdminGroupAccess('internal-admin-portal@sagebrush.church')) {
        let index = dashRoutes.findIndex(item => item.name === 'Utilities')
        if (index === -1) {
          addUtilityDropdown()
          dashRoutes[dashRoutes.length - 1].views.push({
            path: "/Util/Admin",
            name: "Admin",
            icon: ComputerIcon,
            component: ListAdminUtils,
            layout: "/portal",
          })
        } else {
          dashRoutes[index].views.push({
            path: "/Util/Admin",
            name: "Admin",
            icon: ComputerIcon,
            component: ListAdminUtils,
            layout: "/portal",
          })
        }
      }

      if (hasAdminGroupAccess('internal-admin-busform@sagebrush.church')) {
        dashRoutes[myProfileIndex].views.push(
          {
            path: "/MyProfile/HBABAdmin",
            name: "HBAB Admin",
            mini: <Computer />,
            component: HitByABusAdmin,
            layout: "/portal",
          },
        )
      }

      if (hasAccess('internal-poc-board@sagebrush.church', 'member')) {
        let index = dashRoutes.findIndex(item => item.name === 'Utilities')
        if (index === -1) {
          addUtilityDropdown()
          dashRoutes[dashRoutes.length - 1].views.push({
            path: "/Util/PastoralCare",
            name: "POC Board",
            icon: LocalHospitalIcon,
            component: PastoralCare,
            layout: "/portal",
          })
        } else {
          dashRoutes[index].views.push({
            path: "/Util/PastoralCare",
            name: "POC Board",
            icon: LocalHospitalIcon,
            component: PastoralCare,
            layout: "/portal",
          })
        }
      }

      if (hasAccess('internal-kids-packaging@sagebrush.church', 'member')) {
        let index = dashRoutes.findIndex(item => item.name === 'Utilities')
        if (index === -1) {
          addUtilityDropdown()
          dashRoutes[dashRoutes.length - 1].views.push({
            path: "/Util/PackagingCalculator",
            name: "Packaging Calculator",
            icon: InboxIcon,
            component: PackagingCalculator,
            layout: "/portal",
          })
        } else {
          dashRoutes[index].views.push({
            path: "/Util/PackagingCalculator",
            name: "Packaging Calculator",
            icon: InboxIcon,
            component: PackagingCalculator,
            layout: "/portal",
          })
        }
      }

      /*if (hasAdminAccess()) {
        let adminGroups = getAdminGroups();

        dashRoutes.push({
          path: "/administrator",
          name: "Admin",
          icon: Computer,
          layout: "/portal",
          collapse: true,
          views: adminGroups,
        });
      }
      */

      if (hasAccess("internal-digital-pos@sagebrush.church", "member")) {
        dashRoutes[POIndex].views.push({
          path: "/purchase-orders/submitted-to-me",
          name: "Submitted To Me",
          mini: <MarkunreadMailboxIcon />, // HERE
          component: SubmittedToMe,
          layout: "/portal",
          reloadableLink: true,
        });
      }

      if (hasAccess("internal-digitalpo-review@sagebrush.church", "member")) {
        dashRoutes[POIndex].views.push({
          path: "/purchase-orders/submitted-for-review",
          name: "Submitted For Review",
          mini: <RateReviewIcon />, // HERE
          component: SubmittedToReview,
          layout: "/portal",
          reloadableLink: true,
        });
      }

      if (checkinOwner()) {
        dashRoutes[POIndex].views.push({
          path: "/purchase-orders/submitted-by-team",
          name: "Submitted by Others",
          mini: <GroupIcon />, // HERE
          component: SubmittedByTeam,
          layout: "/portal",
          reloadableLink: true,
        });
      }

      if (hasExpenseReportAccess()) {
        dashRoutes[POIndex].views.push({
          path: "/purchase-orders/expense-report",
          name: "Expense Report",
          mini: <ReceiptIcon />, // HERE
          component: ExpenseReport,
          layout: "/portal",
          reloadableLink: true,
        });
      }

      if (
        hasAdminGroupAccess(
          "internal-admin-digitalpo@sagebrush.church"
        )
      ) {
        dashRoutes[POIndex].views[0].component = PurchaseOrderSubmitAdmin;

        // Add access to admin view for PO's
        dashRoutes[POIndex].views.push({
          path: "/purchase-orders/admin",
          name: "Admin View",
          mini: <Computer />,
          component: AdminView,
          layout: "/portal",
          reloadableLink: true,
        });
      }

      if (
        hasAdminGroupAccess("internal-admin-digitalpo@sagebrush.church", "member")
      ) {
        // Add access to admin view for PO's
        dashRoutes[POIndex].views.push({
          path: "/purchase-orders/BudgetManager",
          name: "Budget Codes",
          mini: <AccountBalanceIcon />,
          component: POBudgetsAndCodes,
          layout: "/portal",
          reloadableLink: true,
        });
      }


      if (hasAdminGroupAccess('internal-admin-nf@sagebrush.church')) {
        let index = dashRoutes.findIndex(item => item.name === 'Utilities')
        if (index === -1) {
          addUtilityDropdown()
          dashRoutes[dashRoutes.length - 1].views.push({
            path: "/Util/editDashboard",
            name: "Edit Dashboard",
            icon: AssignmentIcon,
            component: EditDashboard,
            layout: "/portal",
          })
        } else {
          dashRoutes[index].views.push({
            path: "/Util/editDashboard",
            name: "Edit Dashboard",
            icon: AssignmentIcon,
            component: EditDashboard,
            layout: "/portal",
          })
        }
      }

      if (hasAccess("internal-page-manager@sagebrush.church", "member")) {
        let index = dashRoutes.findIndex(item => item.name === 'Utilities')
        if (index === -1) {
          addUtilityDropdown()
          dashRoutes[dashRoutes.length - 1].views.push({
            path: "/Util/pageManagement",
            name: "Page Management",
            mini: <PostAddIcon />,
            component: TrainingCreation,
            layout: "/portal",
          })
        } else {
          dashRoutes[index].views.push({
            path: "/Util/pageManagement",
            name: "Page Management",
            mini: <PostAddIcon />,
            component: TrainingCreation,
            layout: "/portal",
          })
        }
      }

      return dashRoutes;
    },
  }),
});
