import { atom, selector, selectorFamily } from "recoil";
import axios from "axios";
import api from "../api.config";
import Cookies from "js-cookie";
import { decryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

export const loggedInAtom = atom({
  key: "loggedIn",
  default: JSON.parse(sessionStorage.getItem("loggedIn")) || false
});

export const idTokenAtom = atom({
  key: "idToken",
  default: null,
});

export const userAtom = atom({
  key: "user",
  default: decryptData(sessionStorage.getItem("userData"), true) || null,
});

export const jwtAtom = atom({
  key: "jwt",
  default: () => {
    return null;
  },
});

export const guestEmailJWT = atom({
  key: 'guestEmail',
  default: sessionStorage.getItem("GuestEmail") || null,
})

export const userDepartments = atom({
  key: "userDepartmentsAtom",
  default: selector({
    key: "userDepartmentsAtomSelector",
    get: async () => {
      return axios
        .get(`${api.path}/staff_portal_api/v1/getMyGoogleDepartments`)
        .then((res) => {
          return res.data;
        });
    },
  }),
})

export const allDepartments = atom({
  key: "allDepartmentsAtom",
  default: selector({
    key: "allDepartmentsAtomSelector",
    get: async () => {
      return axios
        .get(`${api.path}/staff_portal_api/v1/getAllDepartments`)
        .then((res) => {
          return res.data;
        });
    },
  }),
})

export const groupsAtom = atom({
  key: "groups",
  default: decryptData(sessionStorage.getItem("groups"), true) || null,
});

export const canViewCheckInDataAtom = selectorFamily({
  key: "canViewCheckinData",
  get: (email) => async () => {
    return await axios
      .post(`${api.path}/staff_portal_api/v1/canviewcheckindata`, {
        emailaddr: email,
      })
      .then((res) => {
        return !!res.data;
      });
  },
});

export const userCheckedInAtom = atom({
  key: "userCheckedInAtom",
  default: selector({
    key: "userCheckedInAtomSelector",
    get: async () => {
      return axios
        .get(`${api.path}/staff_portal_api/v1/getstatus`)
        .then((res) => {
          return res.data;
        });
    },
  }),
});

export const validCredToken = () => {
  return axios
    .get(`${api.path}/staff_portal_api/v1/validCredToken`);
}
