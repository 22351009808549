import React, { useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import EventTagGroups from "./EventCalendarManagerTabs/EventTagGroups";
import { adminEventEntriesRequiringApproval } from "state/events";
import ListEventEntries from "./EventTabs/ListEventEntries";

const EventCalanderManagerComp = (props) => {

  const tab = +(props.location.search?.split("=")[1] || 0);

  const [approvalEventEntries, setApprovalEventEntries] = useRecoilState(adminEventEntriesRequiringApproval)

  const tabs = [
    {
      tabName: "Pending",
      tabContent: <ListEventEntries
        events={approvalEventEntries.filter(e => e.status === 'pending')}
      />,
    },
    {
      tabName: "Approved",
      tabContent: <ListEventEntries
        events={approvalEventEntries.filter(e => e.status === 'active')}
      />,
    },
    {
      tabName: "Rejected",
      tabContent: <ListEventEntries
        events={approvalEventEntries.filter(e => e.status === 'rejected')}
      />,
    },
    {
      tabName: "Conflicts",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Event Tags",
      tabContent: <EventTagGroups />,
    },
    {
      tabName: "Failed Google Calls",
      tabContent: <Loading color="blue" />,
    },
  ];


  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const EventCalanderManagerLoading = (props) => {

  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: "Pending",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Approved",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Rejected",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Conflicts",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Event Tags",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Failed Google Calls",
      tabContent: <Loading color="blue" />,
    },
  ];
  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const EventCalanderManager = (props) => {
  return (
    <React.Suspense fallback={<EventCalanderManagerLoading {...props} />}>
      <EventCalanderManagerComp {...props} />
    </React.Suspense>
  )
}

export default EventCalanderManager