import { atom, selector, selectorFamily, atomFamily } from "recoil";
import axios from "axios";
import api from "../api.config";

const PORTAL_API = 'staff_portal_api'


/* Room Manager */
export const campusAtom = atom({
  key: 'campus',
  default: selector({
    key: 'campusSelector',
    get: async () => { return await getCampuses() }
  })
})

export const buildingsAtom = atom({
  key: 'buildings',
  default: selector({
    key: 'buildingsSelector',
    get: async () => { return await getBuildings() }
  })
})

export const roomsAtom = atom({
  key: 'rooms',
  default: selector({
    key: 'roomsSelector',
    get: async () => { return await getRooms() }
  })
})

export const timezonesAtom = atom({
  key: 'timezones',
  default: selector({
    key: 'timezonesSelector',
    get: async () => {
      return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getTimezones`)
        .then((res) => { return res.data })
    }
  })
})

const sortByName = (a, b) => {
  if (a.name > b.name) return 1
  if (a.name < b.name) return -1
  return 0
}

const sortByNumber = (a, b) => {
  if (a.number > b.number) return 1
  if (a.number < b.number) return -1
  return 0
}

export const createCampus = async (campus) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createCampus`, campus).then((res) => { return res.data })
}

export const updateCampus = async (campus) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateCampus`, campus)
}

export const deleteCampus = async (campus) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/deleteCampus`, campus)
}

export const getCampuses = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getCampuses`).then((res) => { return res.data.sort(sortByName) })
}

export const createBuilding = async (building) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createBuilding`, building).then((res) => { return res.data })
}

export const updateBuilding = async (building) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateBuilding`, building)
}

export const deleteBuilding = async (building) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/deleteBuilding`, building)
}

export const getBuildings = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getBuildings`).then((res) => { return res.data.sort(sortByName) })
}

export const createRoom = async (room) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createRoom`, room).then((res) => { return res.data })
}

export const updateRoom = async (room) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateRoom`, room)
}

export const deleteRoom = async (room) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/deleteRoom`, room)
}

export const getRooms = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getRooms`).then((res) => { return res.data.sort(sortByNumber) })
}

export const setOpenHours = async (openHours) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/setOpenHours`, openHours)
}

export const sortDaysOfWeek = (days) => {
  if (!days) {
    return []
  }
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  let sortedDays = daysOfWeek.map(d => null)
  days.forEach(d => { sortedDays[daysOfWeek.indexOf(d)] = d })
  return sortedDays.filter(d => d !== null)
}

/* resource Manager */

export const resourceAtom = atom({
  key: 'resource',
  default: selector({
    key: 'resourceSelector',
    get: async () => { return await getResources() }
  })
})

export const getResources = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getResources`).then((res) => { return res.data.sort(sortByName) })
}

export const createResource = async (resource) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createResource`, resource)
}

export const updateResource = async (resource) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateResource`, resource)
}

export const deleteResource = async (resource) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/deleteResource`, resource)
}

/* Google Event Manager */

export const failedGoogleEventCallsAtom = atom({
  key: 'failedGoogleEventCalls',
  default: selector({
    key: 'failedGoogleEventCallsSelector',
    get: async () => { return await getAllFailedGoogleEventCalls() }
  })
})

export const runAllFailedGoogleEventCalls = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/runAllFailedGoogleEventBackgroundTasks`)
}

export const getAllFailedGoogleEventCalls = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getAllFailedGoogleEventBackgroundTasks`)
    .then((res) => { return res.data })
}

/* Google Resource Manager */

export const failedGoogleResourceCallsAtom = atom({
  key: 'failedGoogleResourceCalls',
  default: selector({
    key: 'failedGoogleResourceCallsSelector',
    get: async () => { return await getAllFailedGoogleResourceCalls() }
  })
})

export const runAllFailedGoogleResourceCalls = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/runAllFailedGoogleResourceBackgroundTasks`)
}

export const getAllFailedGoogleResourceCalls = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getAllFailedGoogleResourceBackgroundTasks`)
    .then((res) => { return res.data })
}

/* Event Tags Manager */

export const eventTagGroupsAtom = atom({
  key: 'eventTagGroups',
  default: selector({
    key: 'eventTagGroupsSelector',
    get: async () => { return await getEventTagGroups() }
  })
})

export const getEventTagGroups = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getEventTagGroup`)
    .then((res) => { return res.data })
}

export const createEventTagGroup = async (eventTagGroup) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createEventTagGroup`, eventTagGroup)
}

export const updateEventTagGroup = async (eventTagGroup) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateEventTagGroup`, eventTagGroup)
}

export const deleteEventTagGroup = async (eventTagGroup) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/deleteEventTagGroup`, eventTagGroup)
}

/* Event Manager */

export const myEvents = atom({
  key: 'myEvents',
  default: selector({
    key: 'myEventsSelector',
    get: async () => { return await getMyEvents() }
  })
})

export const getMyEvents = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getMyEvents`)
    .then((res) => { return res.data })
}

export const myTeamsEvents = atom({
  key: 'myTeamsEvents',
  default: selector({
    key: 'myTeamsEventsSelector',
    get: async () => { return await getMyTeamsEvents() }
  })
})

export const getMyTeamsEvents = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getMyTeamsEvents`)
    .then((res) => { return res.data })
}

export const adminEvents = atom({
  key: 'adminEvents',
  default: selector({
    key: 'adminEventsSelector',
    get: async () => { return await adminGetEvents() }
  })
})

export const adminGetEvents = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/adminGetEvents`)
    .then((res) => { return res.data })
}

export const adminEventEntriesRequiringApproval = atom({
  key: 'adminEventEntriesRequiringApproval',
  default: selector({
    key: 'adminEventEntriesRequiringApprovalSelector',
    get: async () => { return await adminGetEventEntriesRequiringApproval() }
  })
})

export const adminGetEventEntriesRequiringApproval = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/adminGetEventEntriesRequiringApproval`)
    .then((res) => { return res.data })
}

export const teamEventEntriesRequiringApproval = atom({
  key: 'teamEventEntriesRequiringApproval',
  default: selector({
    key: 'teamEventEntriesRequiringApprovalSelector',
    get: async () => { return await getTeamEventEntriesRequiringApproval() }
  })
})

export const getTeamEventEntriesRequiringApproval = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getMyTeamsEventEntriesRequiringApproval`)
    .then((res) => { return res.data })
}

export const calendarEventAtom = atomFamily({
  key: 'calendarEvent',
  default: selectorFamily({
    key: 'calendarEventSelector',
    get: (eventID) => async () => {
      return await getEvent(eventID)
    }
  })
})

export const getEvent = async (eventID) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/getEvent`, { id: eventID }).then((res) => { return res.data })
}

export const eventToEdit = selectorFamily({
  id: "eventToEdit",
  get: (id) => async () => {
    return await getEventToEdit(id)
  },
});

export const getEventToEdit = async (eventID) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/getEventToEdit`, { id: eventID }).then((res) => { return res.data })
}

export const adminEventToEdit = selectorFamily({
  id: "adminEventToEdit",
  get: (id) => async () => {
    return await adminGetEventToEdit(id)
  },
});

export const adminGetEventToEdit = async (eventID) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/adminGetEventToEdit`, { id: eventID }).then((res) => { return res.data })
}

export const createEvent = async (event) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/createEvent`, event)
}

export const updateEvent = async (event) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/updateEvent`, event)
}

export const renewEvent = async (event) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/renewEvent`, event)
}

export const endEvent = async (event) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/endEvent`, event)
}

export const findConflictsForEventEntryReservation = async (reservation) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/findConflictsForEventEntryReservation`, reservation)
    .then((res) => { return res.data })
}

export const getDatesForEventEntries = async (eventEntry) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/getDatesForEventEntries`, eventEntry)
    .then((res) => { return res.data })
}

export const eventCalendarScheduleOutRangesAtom = atom({
  key: 'eventCalendarScheduleOutRanges',
  default: selector({
    key: 'eventCalendarScheduleOutRangesSelector',
    get: async () => { return await getEventCalendarScheduleOutDistances() }
  })
})

export const getEventCalendarScheduleOutDistances = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/event/v1/getEventCalendarScheduleOutDistance`)
    .then((res) => { return res.data })
}

export const reservationsBetweenSelector = selectorFamily({
  id: 'reservationsBetweenSelector',
  get: (request) => async () => {
    return await getReservationsBetween(request)
  },
})

export const getReservationsBetween = async (resRequest) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/getReservationsBetween`, resRequest)
    .then((res) => { return res.data })
}

export const roomsForCampusSelector = selectorFamily({
  id: 'roomsForCampusSelector',
  get: (request) => async () => {
    return await getRoomsForCampus(request)
  },
})

export const getRoomsForCampus = async (request) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/getRoomsForCampus`, request)
    .then((res) => { return res.data.sort(sortByNumber) })
}

export const resourcesForCreatingEventSelector = selectorFamily({
  id: 'resourcesForCreatingEventSelector',
  get: (request) => async () => {
    return await getResourcesForCreatingEvent(request)
  },
})

export const getResourcesForCreatingEvent = async (resRequest) => {
  return await axios.post(`${api.path}/${PORTAL_API}/event/v1/getResourcesForCreatingEvent`, resRequest)
    .then((res) => { return res.data })
}

export const militaryTimeTo12HTime = (time) => {
  let [hours, minutes] = time.split(':')
  let amPM = 'AM'
  if (hours === 0) {
    hours = 12
  } else if (hours > 12) {
    hours = hours % 12
    amPM = 'PM'
  } else if (hours === 12) {
    amPM = 'PM'
  }
  return `${hours}:${minutes} ${amPM}`
}

export const standardDateFormatToUserPreferedLayout = (date) => {
  let [year, month, day] = date.split('-')
  return `${month}/${day}/${year}`
}
