import React, { useState } from 'react'

import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import update from 'immutability-helper'
import {
  Info,
  NavigateNext,
  ExpandMore,
  AccessTime,
  Block,
  EventBusy,
  PriorityHigh,
  AssignmentLate,
  Warning,
  Error
} from '@material-ui/icons';

import ReactTooltip from 'react-tooltip';

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const ResourceReservationSelection = ({ resources, reservations, updateResource, bigEvent, dropdownDefault = true }) => {

  const classes = useStyles()

  const resourceTags = [...(new Set(resources.map(r => r.tags).flat(1)))]
  const resourceTypes = [...(new Set(resources.map(r => r.type).flat(1)))]
  const [resourceTypeToggles, setResourceTypeToggles] = useState(Array(resourceTypes.length).fill(false))

  const emptyResourceFilter = {
    tags: [],
    search: ''
  }

  const [toggleResourceFilter, setToggleResourceFilter] = useState(true)
  const [resourceFilter, setResourceFilter] = useState(emptyResourceFilter)
  const [resourceToggle, setResourceToggle] = useState(dropdownDefault)

  const findIndexOfReservation = (forType, forID) => {
    return reservations.findIndex(r => forType === r.forType && forID === r.forID)
  }

  const filteredResources = resources.filter(r => {
    let bool = true

    if (resourceFilter.tags.length > 0) {
      bool = bool && resourceFilter.tags.every(t => r.tags.includes(t))
    }

    bool = bool && r.search.includes(resourceFilter.search.toLowerCase())

    return bool
  })

  const resourceForDisplay = resourceTypes.map(rType => {
    return {
      type: rType,
      resources: filteredResources.filter(r => r.type === rType),
      selected: resources.filter(r => r.type === rType && findIndexOfReservation('resource', r.id) !== -1).length
    }
  })

  const updateResourceFilter = (updateData) => {
    setResourceFilter(prev => {
      return update(prev, updateData)
    })
  }

  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px 5px 5px 10px' }}
        color='info'
        onClick={() => setResourceToggle(!resourceToggle)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px' }}>Resource Reservations</span>
        {resourceToggle ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
      </Button>
      <div style={{
        // minHeight: resourceToggle ? `${32 * (buildings.length + 1)}px` : '0px',
        // maxHeight: resourceToggle ? `${(115 * (buildings.length + 1)) + 22 * (buildings.map(i => i.floors).reduce((accumulator, currentValue) => { return accumulator + currentValue }, 0)) + rooms.length * 90 + 500}px` : '0px',
        // opacity: resourceToggle ? 1 : 0,
        // transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(resourceToggle) && (
          <div
            style={{
              borderRadius: "4px",
              background: "#eee",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <div>
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '5px 5px 5px 10px',
                  color: '#393939',
                  backgroundColor: '#fff',
                  border: '1px solid #3695d7',
                  margin: '5px 0px'
                }}
                onClick={() => setToggleResourceFilter(!toggleResourceFilter)}
              >
                <span style={{ marginRight: '10px', marginBottom: '-3px', fontSize: '16px', fontWeight: 'bold' }}>Filters</span>
                {toggleResourceFilter ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
              </Button>
              <div
                style={{
                  borderRadius: "4px",
                  background: "#fff",
                  padding: toggleResourceFilter ? "5px" : '0px',
                  border: toggleResourceFilter ? "1px solid #c4c4c4" : '',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  // minHeight: toggleResourceFilter ? `164px` : '0px',
                  // maxHeight: toggleResourceFilter ? `500px` : '0px',
                  // opacity: toggleResourceFilter ? 1 : 0,
                  transition: 'all 1000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                  width: '100%',
                  margin: '5px 0px'
                }}
              >
                {(toggleResourceFilter) && (<>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '200px' }}
                      label='Search'
                      fullWidth
                      variant='outlined'
                      value={resourceFilter.search}
                      onChange={(e) => updateResourceFilter({ search: { $set: e.target.value } })}
                    />
                    <div style={{ margin: '10px 5px', flex: 1, minWidth: '200px' }}>
                      <Autocomplete
                        disablePortal
                        multiple
                        options={resourceTags}
                        value={resourceFilter.tags}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tags"
                            variant="outlined"
                          />
                        )}
                        fullWidth
                        onChange={(e, v) => updateResourceFilter({ tags: { $set: v } })}
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                      />
                    </div>
                  </div>
                </>)}
              </div>
            </div>
            {resourceForDisplay.map((type, index) => {
              return (
                <div
                  key={index}
                >
                  {type.type.length > 0 && (
                    <>
                      <Button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          padding: '5px 5px 5px 10px',
                          color: '#393939',
                          backgroundColor: '#fff',
                          border: '1px solid #3695d7',
                          margin: '5px 0px'
                        }}
                        onClick={() => setResourceTypeToggles(prev => {
                          return update(prev, {
                            [index]: { $set: !resourceTypeToggles[index] }
                          })
                        })}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '-3px', marginRight: '10px', flexWrap: 'wrap' }}>
                          <span style={{ marginRight: '5px', fontSize: '16px', fontWeight: 'bold' }}>{type.type}</span>
                          <span style={{ textTransform: 'none' }}>{type.selected} Selected</span>
                        </div>
                        {resourceTypeToggles[index] ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
                      </Button>
                      <div
                        style={{
                          borderRadius: "4px",
                          background: "#fff",
                          padding: resourceTypeToggles[index] ? "5px" : '0px',
                          border: resourceTypeToggles[index] ? "1px solid #c4c4c4" : '',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          // maxHeight: resourceTypeToggles[index] ? `${115 + 22 * b.building.floors + b.rooms.length * 90}px` : '0px',
                          // minHeight: resourceTypeToggles[index] ? `${15 + 22 + 77}px` : '0px',
                          opacity: resourceTypeToggles[index] ? 1 : 0,
                          transition: 'all 1000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                          width: '100%',
                          margin: '5px 0px'
                        }}
                      >
                        {(resourceTypeToggles[index]) && (
                          <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0px 5px 8px 5px' }}>
                            {type.resources.map((r, index) => {
                              return (
                                <DisplayResource
                                  key={r.id}
                                  resource={r}
                                  bigEvent={bigEvent}
                                  reservation={reservations.find(res => 'resource' === res.forType && r.id === res.forID)}
                                  reservationIndex={reservations.findIndex(res => 'resource' === res.forType && r.id === res.forID)}
                                  updateResource={updateResource}
                                />
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
      {(!resourceToggle && reservations.filter(res => res.forType === 'resource').length !== 0) && (
        <div
          style={{
            borderRadius: "4px",
            background: "#eee",
            padding: "5px 5px 9px 5px",
            border: "1px solid #c4c4c4",
            alignItems: 'center',
            width: '100%',
            margin: '5px 0px'
          }}
        >
          <div
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {resources.filter(r => findIndexOfReservation('resource', r.id) !== -1).map(r => {
              return (
                <DisplayResource
                  key={r.id}
                  resource={r}
                  bigEvent={bigEvent}
                  reservation={reservations.find(res => 'resource' === res.forType && r.id === res.forID)}
                  reservationIndex={reservations.findIndex(res => 'resource' === res.forType && r.id === res.forID)}
                  updateResource={updateResource}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

const DisplayResource = ({ resource, updateResource, reservation, reservationIndex, bigEvent }) => {
  const emptyReservation = {
    forType: 'resource',
    forID: resource.id,
    resourceType: resource.type,
    resourceData: resource.typeData,
    quantity: 0
  }
  const [resourceData, setResourcesData] = useState(reservation ? reservation : emptyReservation)

  const updateResourceData = (updateData) => {
    setResourcesData(prev => {
      return update(prev, updateData)
    })
  }
  return (
    <>
      {resource.type === 'general' && (
        <div style={{
          margin: '0px 5px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}>
          <div
            style={{ height: '10px', marginTop: '-5px' }}
          >
            <span style={{
              margin: '0px 10px',
              fontSize: '10px'
            }}>
              {reservation ? 'Selected' : ''}
            </span>
          </div>
          <div
            style={{
              margin: '6px 0px 2px 0px',
              padding: '5px 7px 5px 10px',
              backgroundColor: reservation ? '#fff' : '#ddd',
              border: reservation ? '1px solid #3695d7' : '1px solid #bbb',
              color: '#393939',
              textTransform: 'none',
              borderRadius: '6px',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <span>{resource.name}</span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '5px',
                flexWrap: 'wrap'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                <span style={{ marginTop: '1px' }}>Available: {resource.available} / {resource.quantity}</span>
                {(resourceData.quantity > resource.available) && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Error
                      style={{ margin: '0px', height: '20px', color: '#ff9800', cursor: 'pointer' }}
                      data-tip
                      data-for={`WarningInfo-${resource.id}`}
                    />
                    <ReactTooltip
                      delayHide={50}
                      delayShow={500}
                      effect="solid"
                      id={`WarningInfo-${resource.id}`}
                    >
                      <span>
                        Allowed but will take < br />
                        from other event(s)
                      </span>
                    </ReactTooltip>
                  </div>
                )}
              </div>
              <TextField
                style={{ minWidth: '200px', marginLeft: '5px', backgroundColor: '#fff', borderRadius: '4px' }}
                label='Quantity'
                type="number"
                variant='outlined'
                value={resourceData.quantity}
                onChange={(e) => {
                  let val = e.target.value

                  if (val !== '') {
                    val = parseInt(val)
                    if (val < 0) {
                      val = 0
                    } else if (!bigEvent) {
                      if (val >= resource.available) {
                        val = resource.available
                      }
                    } else if (val >= resource.quantity) {
                      val = resource.quantity
                    }
                  }

                  let update = {
                    quantity: { $set: val }
                  }
                  updateResourceData(update)

                  if (val === '' || val === 0) {
                    if (reservation) {
                      updateResource({ $splice: [[reservationIndex, 1]] })
                    }
                  } else {
                    if (reservation) {
                      updateResource({ [reservationIndex]: update })
                    } else {
                      let res = { ...resourceData }
                      res.quantity = val
                      updateResource({ $push: [res] })
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ResourceReservationSelection