import Loading from 'components/Loading/Loading'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import InfoIcon from '@material-ui/icons/Info';

import update from 'immutability-helper'
import {
  createEvent,
  updateEvent,
  findConflictsForEventEntryReservation,
  getDatesForEventEntries,
  campusAtom,
  buildingsAtom,
  roomsAtom,
  resourceAtom,
  eventCalendarScheduleOutRangesAtom,
  getReservationsBetween,
  eventTagGroupsAtom,
  getRoomsForCampus,
  resourcesForCreatingEventSelector
} from 'state/events'
import DaysOfWeekSelection from 'components/DaysOfWeekSelection/DaysOfWeekSelection';
import HelperText from 'components/HelperText/HelperText';
import { Info, NavigateNext, ExpandMore, AccessTime, Block, EventBusy, PriorityHigh, AssignmentLate } from '@material-ui/icons';
import ReactTooltip from 'react-tooltip';
import EventEntryValidation from 'components/EventEntryValidation/EventEntryValidation';
import RoomReservationSelection from 'components/RoomReservationSelection/RoomReservationSelection';
import { getResourcesForCreatingEvent } from 'state/events';
import ResourceReservationSelection from 'components/ResourceReservationSelection/ResourceReservationSelection';
import { decryptData } from 'assets/jss/EncriptDecrypt/EncryptDecrypt';
import { myDepartmentsAtom } from 'state/ticketSystem';

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const CreateEditEventComp = (props) => {

  const classes = useStyles()

  const campuses = useRecoilValue(campusAtom)
  const buildings = useRecoilValue(buildingsAtom)
  const eventRanges = useRecoilValue(eventCalendarScheduleOutRangesAtom)
  const eventTags = useRecoilValue(eventTagGroupsAtom)
  const departments = useRecoilValue(myDepartmentsAtom)

  const search = window.location.search?.split("?")[1] || null
  const searchData = search ? decryptData(search) : {}
  const returnLoc = searchData.returnLocation

  const formattedEvent = props.event ? {
    ...props.event,
    campus: campuses.find(c => c.id === props.event.campusID),
    tag: eventTags.find(t => t._id === props.event.tag),
    createdByDepartment: departments.find(d => d.email === props.event.createdByDepartment)
  } : null

  const recurringTypes = [
    'Does Not Repeat',
    'Daily',
    'Weekly',
    'BiWeekly',
    'Monthly',
    'Custom'
  ]

  const repeatEveryIntervals = [
    'week', 'month'
  ]

  const daysOfWeek = [
    {
      id: 0,
      label: 'S'
    },
    {
      id: 1,
      label: 'M'
    },
    {
      id: 2,
      label: 'T'
    },
    {
      id: 3,
      label: 'W'
    },
    {
      id: 4,
      label: 'T'
    },
    {
      id: 5,
      label: 'F'
    },
    {
      id: 6,
      label: 'S'
    },
  ]

  const emptyEvent = {
    name: '',
    description: "",
    reservations: [],
    bigEvent: null,
    schedule: [],
    campus: null,
    createdByDepartment: departments ? departments[0] : null,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    recurringType: recurringTypes[0],
    repeatEvery: 1,
    repeatEveryInterval: repeatEveryIntervals[0],
    repeatOn: [], // days of week starting with 0 for sunday
    repeatEnds: '',
    renewReminder: null,
    tag: null,
    eventEntriesData: []
  }

  // const emptyEvent = {
  //   name: 'test',
  //   description: "",
  //   reservations: [],
  //   bigEvent: false,
  //   schedule: [],
  //   campus: campuses[6],
  //   startDate: '2025-01-16',
  //   endDate: '2025-01-16',
  //   startTime: '12:00',
  //   endTime: '16:00',
  //   recurringType: recurringTypes[0],
  //   repeatEvery: 1,
  //   repeatEveryInterval: repeatEveryIntervals[0],
  //   repeatOn: [], // days of week starting with 0 for sunday
  //   repeatEnds: '',
  //   renewReminder: null,
  //   tag: eventTags[0],
  //   eventEntriesData: []
  // }

  const getEmptyErrors = (event) => {
    let emptyErrors = {
      name: { hasError: false, message: '' },
      description: { hasError: false, message: '' },
      bigEvent: { hasError: false, message: '' },
      schedule: [],
      campus: { hasError: false, message: '' },
      createdByDepartment: { hasError: false, message: '' },
      startDate: { hasError: false, message: '' },
      endDate: { hasError: false, message: '' },
      startTime: { hasError: false, message: '' },
      endTime: { hasError: false, message: '' },
      recurringType: { hasError: false, message: '' },
      repeatEvery: { hasError: false, message: '' },
      repeatEveryInterval: { hasError: false, message: '' },
      repeatOn: { hasError: false, message: '' },
      repeatEnds: { hasError: false, message: '' },
      renewReminder: { hasError: false, message: '' },
      tag: { hasError: false, message: '' },
    }

    if (event.schedule) {
      event.schedule.forEach(() => emptyErrors.schedule.push({ hasError: false, message: '' }))
    }

    return emptyErrors
  }

  const [event, setEvent] = useState(formattedEvent ? formattedEvent : emptyEvent)
  const [rooms, setRooms] = useState([])
  const [resources, setResources] = useState([])
  const [errors, setErrors] = useState(getEmptyErrors(event))
  const [loading, setLoading] = useState(false)
  const [initalSubmitLoading, setInitalSubmitLoading] = useState(false)
  const [existingReservationsAtTime, setExistingReservationsAtTime] = useState([])
  const [dateTimeChanged, setDateTimeChanged] = useState(true)

  const [reservationsChanged, setReservationsChanged] = useState(true)
  const [uneditedEventEntries, setUneditedEventEntries] = useState([])
  const [eventEntryErrors, setEventEntryErrors] = useState([])
  const [eventEntryLoading, setEventEntryLoading] = useState([])

  const eventHasWarning = eventEntryErrors.some(e => e.warning)
  const eventHasError = eventEntryErrors.some(e => e.error)
  const eventNotLoading = eventEntryLoading.every(e => !e)

  const [acknowledgeWarnings, setAcknowledgeWarnings] = useState(false)

  const roomsFormated = rooms.map(r => {

    let room = {
      ...r,
      occupancy: parseInt(r.occupancy)
    }

    let number = r.number.split('-')
    room.number = number[number.length - 1]

    let existingReservationsForRoom = existingReservationsAtTime.filter(res => res.forID === room.id && res.forType === 'room')
    room.reservationConflicts = existingReservationsForRoom.length
    room.bigEventConflict = existingReservationsForRoom.indexOf(i => i.bigEvent) === -1 ? false : true


    if (!r.openHours) {
      let building = buildings.find(b => b.id === r.buildingID)
      if (building) {
        if (building.openHours) {
          room.openHours = building.openHours
        } else {
          let campus = campuses.find(c => c.id === r.campusID)
          if (campus) {
            room.openHours = campus.openHours
          }
        }
      }
    }

    let roomSearch = `${r.name} room ${r.number} occupancy ${r.occupancy} ${r.tags.join(', ')}`
    room.search = roomSearch.toLowerCase()

    return room
  })

  const selectableRooms = event.campus ? roomsFormated.filter(r => r.campusID === event.campus.id) : []

  const formatDateString = (date) => {
    const displayStart = date.split('-')
    return `${displayStart[1]}/${displayStart[2]}/${displayStart[0]}`
  }

  const getDayOfWeek = (date = event.startDate) => {
    return date ? daysOfWeek[(new Date(date).getDay() + 1) % 7] : null
  }

  const formatedResources = resources.map(r => {
    let resource = {
      ...r,
      available: r.quantity ? r.quantity - r.reservedGeneral - r.reservedBigEvent : 0,
    }

    let rSearch = `${r.name} ${r.location} ${r.tags.join(', ')}`
    resource.search = rSearch.toLowerCase()

    return resource
  })

  const findIndexOfReservation = (forType, forID) => {
    return event.reservations.findIndex(r => forType === r.forType && forID === r.forID)
  }

  const addRemoveRoom = (room) => {
    setReservationsChanged(true)
    let reservation = {
      forType: 'room',
      forID: room.id,
      buildingID: room.buildingID
    }
    let index = findIndexOfReservation('room', room.id)
    if (index === -1) {
      setEvent(prev => {
        return update(prev, {
          reservations: { $push: [reservation] }
        })
      })
    } else {
      setEvent(prev => {
        return update(prev, {
          reservations: { $splice: [[index, 1]] }
        })
      })
    }

  }

  const selectableBuildings = event.campus ? buildings.filter(b => b.campusID === event.campus.id) : []

  const updateResource = (updateData) => {
    setReservationsChanged(true)
    setEvent(prev => {
      return update(prev, {
        reservations: updateData
      })
    })
  }

  const confirmReservations = () => {
    let eventEntryErrorsTemp = []
    let eventEntries = event.eventEntriesData.map(e => {
      eventEntryErrorsTemp.push({ warning: false, error: false, messages: [] })
      return {
        ...e,
        reservations: event.reservations
      }
    })
    setEventEntryErrors(eventEntryErrorsTemp)
    setEventEntryLoading(eventEntryErrorsTemp.map(() => true))
    updateEventData({ eventEntriesData: { $set: eventEntries } })
    setUneditedEventEntries(eventEntries)
    setReservationsChanged(false)
    setAcknowledgeWarnings(false)
  }

  const validateInitialForm = () => {
    let displayStart = formatDateString(eventRanges.start)
    let displayRecurring = formatDateString(eventRanges.recurring)

    setErrors(getEmptyErrors(event))
    let valid = true

    if (!(event.name.trim())) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          name: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    }

    if (!event.campus) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          campus: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    } else if (!campuses.find(c => c.id === event.campus.id)) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          campus: {
            $set: {
              hasError: true,
              message: `*Invalid campus selected`
            }
          }
        })
      })
    }

    if (!event.createdByDepartment) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          createdByDepartment: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    } else if (!departments.find(c => c.email === event.createdByDepartment.email)) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          createdByDepartment: {
            $set: {
              hasError: true,
              message: `*Invalid department selected`
            }
          }
        })
      })
    }

    if (!event.tag) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          tag: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    } else if (!eventTags.find(t => t._id === event.tag._id)) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          tag: {
            $set: {
              hasError: true,
              message: `*Invalid tag selected`
            }
          }
        })
      })
    }

    if (event.bigEvent !== true && event.bigEvent !== false) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          bigEvent: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    }

    if (!event.startDate) {
      setErrors(prevState => {
        return update(prevState, {
          startDate: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
      valid = false
    } else if (event.startDate > eventRanges.start && !event.bigEvent) {
      setErrors(prevState => {
        return update(prevState, {
          startDate: {
            $set: {
              hasError: true,
              message: `*Cannot exceed ${displayStart}`
            }
          }
        })
      })
      valid = false
    } else if (event.startDate > event.endDate) {
      setErrors(prevState => {
        return update(prevState, {
          startDate: {
            $set: {
              hasError: true,
              message: `*Event can't start after it ends`
            }
          }
        })
      })
      valid = false
    }

    if (!event.startTime) {
      setErrors(prevState => {
        return update(prevState, {
          startTime: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
      valid = false
    } else if (event.startDate === event.endDate && event.startTime >= event.endTime) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          startTime: {
            $set: {
              hasError: true,
              message: `*Event can't start before it ends`
            }
          }
        })
      })
    }

    if (!event.endDate) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endDate: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    } else if (event.endDate > eventRanges.start && !event.bigEvent) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endDate: {
            $set: {
              hasError: true,
              message: `*Cannot exceed ${displayStart}`
            }
          }
        })
      })
    } else if (event.startDate > event.endDate) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endDate: {
            $set: {
              hasError: true,
              message: `*Event can't end before it starts`
            }
          }
        })
      })
    }

    if (!event.endTime) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endTime: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    } else if (event.startDate === event.endDate && event.startTime >= event.endTime) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endTime: {
            $set: {
              hasError: true,
              message: `*Event can't end before it starts`
            }
          }
        })
      })
    }

    if (!event.recurringType) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          recurringType: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    } else {

      if (event.recurringType !== 'Does Not Repeat') {

        if (!event.repeatEnds) {
          valid = false
          setErrors(prevState => {
            return update(prevState, {
              repeatEnds: {
                $set: {
                  hasError: true,
                  message: `*Required`
                }
              }
            })
          })
        } else if (event.repeatEnds > eventRanges.recurring && !event.bigEvent) {
          valid = false
          setErrors(prevState => {
            return update(prevState, {
              repeatEnds: {
                $set: {
                  hasError: true,
                  message: `*Cannot exceed ${displayRecurring}`
                }
              }
            })
          })
        } else if (event.repeatEnds < event.endDate) {
          valid = false
          setErrors(prevState => {
            return update(prevState, {
              repeatEnds: {
                $set: {
                  hasError: true,
                  message: `*Must be greater than End Date`
                }
              }
            })
          })
        }

        if (event.renewReminder !== true && event.renewReminder !== false && event.repeatEnds !== '' && event.repeatEnds > eventRanges.start) {
          valid = false
          setErrors(prevState => {
            return update(prevState, {
              renewReminder: {
                $set: {
                  hasError: true,
                  message: `*Required`
                }
              }
            })
          })
        }

        if (event.recurringType === 'Custom') {
          if (!event.repeatEvery) {
            valid = false
            setErrors(prevState => {
              return update(prevState, {
                repeatEvery: {
                  $set: {
                    hasError: true,
                    message: `*Required`
                  }
                }
              })
            })
          } else if (event.repeatEvery <= 0) {
            valid = false
            setErrors(prevState => {
              return update(prevState, {
                repeatEvery: {
                  $set: {
                    hasError: true,
                    message: `*Must be greater than 0`
                  }
                }
              })
            })
          }

          if (!event.repeatEveryInterval) {
            valid = false
            setErrors(prevState => {
              return update(prevState, {
                repeatEveryInterval: {
                  $set: {
                    hasError: true,
                    message: `*Required`
                  }
                }
              })
            })
          }

          if (event.repeatOn.length <= 0) {
            valid = false
            setErrors(prevState => {
              return update(prevState, {
                repeatOn: {
                  $set: {
                    hasError: true,
                    message: `*Required`
                  }
                }
              })
            })
          }
        }
      }
    }

    return valid
  }

  const submitInitialForm = async (eventData = event, flagEventEntries = false) => {
    if (validateInitialForm()) {
      setInitalSubmitLoading(true)
      setDateTimeChanged(false)
      setAcknowledgeWarnings(false)
      let start = `${eventData.startDate} ${eventData.startTime}`
      let end = `${eventData.endDate} ${eventData.endTime}`
      let reservations = await getReservationsBetween({
        campusID: eventData.campus.id,
        start: start,
        end: end,
        excludeEventID: eventData.id
      })

      setExistingReservationsAtTime(reservations)
      if (!flagEventEntries) {
        let timeslots = await getDatesForEventEntries({
          start: start,
          end: end,
          recurringType: eventData.recurringType,
          repeatOn: eventData.repeatOn,
          repeatEvery: eventData.repeatEvery,
          repeatEveryInterval: eventData.repeatEveryInterval,
          repeatEnds: eventData.repeatEnds
        })
        timeslots = timeslots.map(ts => {
          const [startDate, startTime] = ts.start.split(' ')
          const [endDate, endTime] = ts.end.split(' ')
          return {
            ...ts,
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime
          }
        })
        setEvent(prev => {
          return update(prev, {
            eventEntriesData: { $set: timeslots }
          })
        })
      } else {
        let timeslots = eventData.eventEntriesData.map(ts => {
          const [startDate, startTime] = ts.start.split(' ')
          const [endDate, endTime] = ts.end.split(' ')
          return {
            ...ts,
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime
          }
        })
        setEvent(prev => {
          return update(prev, {
            eventEntriesData: { $set: timeslots }
          })
        })
      }

      let r = await getRoomsForCampus({
        campusID: eventData.campus.id,
        start: start,
        end: end
      })
      setRooms(r)

      let res = await getResourcesForCreatingEvent({
        campusID: eventData.campus.id,
        start: start,
        end: end,
        excludeEventID: eventData.id
      })
      setResources(res)

      setInitalSubmitLoading(false)
    }
  }

  const updateEventData = (updateData) => {
    setEvent(prev => {
      return update(prev, updateData)
    })
  }

  const updateEventDataDateTimeChange = (updateData) => {
    setDateTimeChanged(true)
    setReservationsChanged(true)
    setEvent(prev => {
      return update(prev, updateData)
    })
  }

  const addDay = (day) => {
    updateEventDataDateTimeChange({ repeatOn: { $push: [day] } })
  }

  const removeDay = (index) => {
    if (event.repeatOn[index] !== getDayOfWeek(event.startDate).id) {
      updateEventDataDateTimeChange({ repeatOn: { $splice: [[index, 1]] } })
    }
  }

  const submitForm = async () => {
    setLoading(true)
    let newEvent = {
      name: event.name,
      description: event.description,
      reservations: event.reservations,
      bigEvent: event.bigEvent,
      campusID: event.campus.id,
      createdByDepartment: event.createdByDepartment.email,
      start: `${event.startDate} ${event.startTime}`,
      end: `${event.endDate} ${event.endTime}`,
      recurringType: event.recurringType,
      repeatEvery: event.repeatEvery,
      repeatEveryInterval: event.repeatEveryInterval,
      repeatOn: [...event.repeatOn].sort((a, b) => a - b),
      repeatEnds: event.repeatEnds,
      renewReminder: event.renewReminder === null ? false : event.renewReminder,
      tag: event.tag._id,
      eventEntriesData: event.eventEntriesData.map(entry => {
        return {
          status: entry.status,
          start: `${entry.startDate} ${entry.startTime}`,
          end: `${entry.endDate} ${entry.endTime}`,
          edited: entry.edited,
          reservations: entry.reservations,
          startEndRecurringRef: entry.startEndRecurringRef
        }
      })
    }
    if (event.id) {
      newEvent.id = event.id
      await updateEvent(newEvent)
      if (returnLoc) {
        window.location.href = returnLoc
      }
    } else {
      await createEvent(newEvent)
      if (returnLoc) {
        window.location.href = returnLoc
      } else {
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    if (formattedEvent) {
      submitInitialForm(formattedEvent, true)
    }
  }, [])

  return (
    <React.Fragment>
      <Card style={{ maxWidth: '1000px', marginBottom: '10px' }}>
        <CardHeader color='info' text>
          <CardText color='info' style={{ fontSize: '16px', fontWeight: '400', padding: '10px' }}>
            {formattedEvent ? formattedEvent.id ? 'Edit Event' : 'Duplicate Event' : 'Create Event'}
          </CardText>
        </CardHeader>
        <CardBody style={{ padding: '10px' }}>
          {loading ?
            <Loading color='blue' />
            :
            <div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  style={{ flex: 1, margin: '10px 5px' }}
                  label='Name'
                  required
                  fullWidth
                  variant='outlined'
                  error={errors.name.hasError}
                  helperText={errors.name.message}
                  value={event.name}
                  onChange={(e) => updateEventData({ name: { $set: e.target.value } })}
                />
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ margin: '10px 5px', flex: 1, minWidth: '225px' }}>
                  <Autocomplete
                    disablePortal
                    options={departments}
                    value={event.createdByDepartment}
                    getOptionSelected={(option) => {
                      return option.email === event.createdByDepartment.email
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Department"
                        variant="outlined"
                        error={errors.createdByDepartment.hasError}
                        helperText={errors.createdByDepartment.message}
                      />
                    )}
                    fullWidth
                    onChange={(e, v) => updateEventData({ createdByDepartment: { $set: v } })}
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    disableClearable
                  />
                </div>
                <div style={{ margin: '10px 5px', flex: 1, minWidth: '225px' }}>
                  <Autocomplete
                    disablePortal
                    options={campuses}
                    value={event.campus}
                    getOptionSelected={(option) => {
                      return option.id === event.campus.id
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Campus"
                        variant="outlined"
                        error={errors.campus.hasError}
                        helperText={errors.campus.message}
                      />
                    )}
                    fullWidth
                    onChange={(e, v) => updateEventDataDateTimeChange({ campus: { $set: v } })}
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    disableClearable
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ margin: '10px 5px', flex: 1, minWidth: '225px' }}>
                  <Autocomplete
                    disablePortal
                    options={eventTags}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option) => option._id === event.tag._id}
                    value={event.tag}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Tag"
                        variant="outlined"
                        error={errors.tag.hasError}
                        helperText={errors.tag.message}
                      />
                    )}
                    fullWidth
                    onChange={(e, v) => updateEventData({ tag: { $set: v } })}
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    disableClearable
                  />
                </div>
                <div style={{ margin: '5px 5px 10px 5px', flex: 1 }}>
                  <div style={{ display: 'flex', alignItems: 'center', paddingTop: '11px' }}>
                    <div style={{ marginTop: '-11px', flex: 1 }}>
                      <div style={{ margin: '-6px 0px 0px 9px' }}>
                        <span style={{
                          position: 'relative',
                          margin: '0px',
                          padding: '0px 5px',
                          color: errors.bigEvent.hasError ? '#f44336' : '#757575',
                          backgroundColor: '#fff',
                          zIndex: 2,
                          fontSize: '12px'
                        }}>
                          Big Event *
                        </span>
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          zIndex: 1,
                          display: 'flex',
                          flexWrap: 'wrap',
                          borderRadius: "4px",
                          background: "#fff",
                          padding: "1px 12px",
                          border: errors.bigEvent.hasError ? "1px solid #f44336" : "1px solid #c4c4c4",
                          alignItems: 'center',
                          marginTop: '-11px',
                          flexWrap: 'wrap',
                          marginRight: '5px'
                        }}
                      >

                        <FormControlLabel
                          control={
                            <Radio
                              checked={event.bigEvent === true}
                              name="bigEvent"
                              value="yes"
                              onChange={() => updateEventData({ bigEvent: { $set: true } })}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          style={{ color: '#797979' }}
                          label="Yes"
                        />

                        <FormControlLabel
                          control={
                            <Radio
                              checked={event.bigEvent === false}
                              name="bigEvent"
                              value="no"
                              onChange={() => updateEventData({ bigEvent: { $set: false } })}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          style={{ color: '#797979' }}
                          label="No"
                        />
                      </div>
                    </div>
                    <Info
                      style={{ marginTop: '-11px', color: '#797979', cursor: 'pointer' }}
                      data-tip
                      data-for={'BigEventInfo'}
                    />
                    <ReactTooltip
                      delayHide={50}
                      delayShow={500}
                      effect="solid"
                      id={'BigEventInfo'}
                    >
                      <span>
                        A big event requires approval, <br />
                        but can be scheduled as far in <br />
                        advance as you'd like. Otherwise, <br />
                        an event can only be created up to <br />
                        8 weeks in advance ({formatDateString(eventRanges.start)}) <br />
                        and can recur till 16 weeks from <br />
                        now ({formatDateString(eventRanges.recurring)}).
                      </span>
                    </ReactTooltip>
                  </div>
                  <HelperText error={errors.bigEvent.hasError} helperText={errors.bigEvent.message} />
                </div>
              </div>

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  style={{ margin: '10px 5px' }}
                  label="Description"
                  variant="outlined"
                  fullWidth
                  value={event.description}
                  multiline
                  minRows={4}
                  onChange={(e) => updateEventData({ description: { $set: e.target.value } })}
                />
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    style={{ flex: 1, margin: '10px 5px', minWidth: '170px' }}
                    type="date"
                    variant="outlined"
                    label='Start Date'
                    required
                    helperText={errors.startDate.message}
                    error={errors.startDate.hasError}
                    value={event.startDate}
                    onChange={(e) => {
                      let dayOfWeek = getDayOfWeek(e.target.value).id
                      updateEventDataDateTimeChange({
                        startDate: { $set: e.target.value },
                      })
                      if (event.repeatOn.indexOf(dayOfWeek) === -1) {
                        addDay(dayOfWeek)
                      }
                      if (!event.endDate) {
                        updateEventDataDateTimeChange({ endDate: { $set: e.target.value } })
                      } else if (event.endDate < e.target.value) {
                        updateEventDataDateTimeChange({ endDate: { $set: e.target.value } })
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    style={{ flex: 1, margin: '10px 5px', minWidth: '140px' }}
                    type="time"
                    variant="outlined"
                    label='Start Time'
                    required
                    helperText={errors.startTime.message}
                    error={errors.startTime.hasError}
                    value={event.startTime}
                    onChange={(e) => updateEventDataDateTimeChange({ startTime: { $set: e.target.value } })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <span style={{ margin: '10px', fontSize: '16px' }}> to </span>

                <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    style={{ flex: 1, margin: '10px 5px', minWidth: '170px' }}
                    type="date"
                    variant="outlined"
                    label='End Date'
                    required
                    helperText={errors.endDate.message}
                    error={errors.endDate.hasError}
                    value={event.endDate}
                    onChange={(e) => updateEventDataDateTimeChange({ endDate: { $set: e.target.value } })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    style={{ flex: 1, margin: '10px 5px', minWidth: '140px' }}
                    type="time"
                    variant="outlined"
                    label='End Time'
                    required
                    helperText={errors.endTime.message}
                    error={errors.endTime.hasError}
                    value={event.endTime}
                    onChange={(e) => updateEventDataDateTimeChange({ endTime: { $set: e.target.value } })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ margin: '10px 5px', flex: 1, minWidth: '225px' }}>
                  <Autocomplete
                    disablePortal
                    options={recurringTypes}
                    value={event.recurringType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Recurring Type"
                        variant="outlined"
                        error={errors.recurringType.hasError}
                        helperText={errors.recurringType.message}
                      />
                    )}
                    fullWidth
                    onChange={(e, v) => updateEventDataDateTimeChange({ recurringType: { $set: v } })}
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    disableClearable
                  />
                </div>
                {(event.recurringType !== 'Does Not Repeat' && event.recurringType !== 'Custom') ?
                  <TextField
                    style={{ flex: 1, margin: '10px 5px', minWidth: '225px' }}
                    type="date"
                    variant="outlined"
                    label='Recurring Ends'
                    required
                    helperText={errors.repeatEnds.message}
                    error={errors.repeatEnds.hasError}
                    value={event.repeatEnds}
                    onChange={(e) => updateEventDataDateTimeChange({ repeatEnds: { $set: e.target.value } })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  :
                  <div style={{ height: '0px', flex: 1, minWidth: '170px', margin: '0px 5px' }} />}
              </div>
              {(event.recurringType === 'Custom') && (
                <>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      style={{ flex: 1, margin: '10px 5px' }}
                      type="number"
                      variant="outlined"
                      label='Repeat Every'
                      required
                      helperText={errors.repeatEvery.message}
                      error={errors.repeatEvery.hasError}
                      value={event.repeatEvery}
                      onChange={(e) => {
                        let val = e.target.value
                        if (val !== '') {
                          val = parseInt(val)
                          if (val === 0) {
                            val = 1
                          } else if (val < 0) {
                            val = val * -1
                          }
                        }
                        updateEventDataDateTimeChange({ repeatEvery: { $set: val } })
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <div style={{ margin: '10px 5px', flex: 1 }}>
                      <Autocomplete
                        disablePortal
                        options={repeatEveryIntervals}
                        value={typeof event.repeatEvery === 'number' ? event.repeatEvery > 1 ? `${event.repeatEveryInterval}s` : event.repeatEveryInterval : event.repeatEveryInterval}
                        getOptionLabel={(option) => {
                          return typeof event.repeatEvery === 'number' ? event.repeatEvery > 1 ? `${option}` : option : option
                        }}
                        getOptionSelected={option => {
                          return option === event.repeatEveryInterval
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Repeat Every Interval"
                            variant="outlined"
                            error={errors.repeatEveryInterval.hasError}
                            helperText={errors.repeatEveryInterval.message}
                          />
                        )}
                        fullWidth
                        onChange={(e, v) => updateEventDataDateTimeChange({ repeatEveryInterval: { $set: v } })}
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        disableClearable
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ margin: '10px 5px', flex: 1 }}>
                      <DaysOfWeekSelection days={event.repeatOn} addDay={addDay} customDaysOfWeek={daysOfWeek} removeDay={removeDay} error={errors.repeatOn} required={true} label={'Repeat On'} />
                    </div>
                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '170px' }}
                      type="date"
                      variant="outlined"
                      label='Repeat Ends'
                      required
                      helperText={errors.repeatEnds.message}
                      error={errors.repeatEnds.hasError}
                      value={event.repeatEnds}
                      onChange={(e) => updateEventDataDateTimeChange({ repeatEnds: { $set: e.target.value } })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </>
              )}
              {(event.recurringType !== 'Does Not Repeat' && event.repeatEnds !== '' && event.repeatEnds > eventRanges.start) && (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '10px 5px', flex: 1 }}>
                    <div style={{ marginTop: '-11px' }}>
                      <div style={{ margin: '-6px 0px 0px 9px' }}>
                        <span style={{
                          position: 'relative',
                          margin: '0px',
                          padding: '0px 5px',
                          color: errors.renewReminder.hasError ? '#f44336' : '#757575',
                          backgroundColor: '#fff',
                          zIndex: 2,
                          fontSize: '12px'
                        }}>
                          Renewal Reminder *
                        </span>
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          zIndex: 1,
                          display: 'flex',
                          flexWrap: 'wrap',
                          borderRadius: "4px",
                          background: "#fff",
                          padding: "1px 12px",
                          border: errors.renewReminder.hasError ? "1px solid #f44336" : "1px solid #c4c4c4",
                          alignItems: 'center',
                          marginTop: '-11px',
                          flexWrap: 'wrap'
                        }}
                      >

                        <FormControlLabel
                          control={
                            <Radio
                              checked={event.renewReminder === true}
                              name="renewReminder"
                              value="yes"
                              onChange={() => setEvent(prev => {
                                return update(prev, {
                                  renewReminder: { $set: true }
                                })
                              })}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          style={{ color: '#797979' }}
                          label="Yes"
                        />

                        <FormControlLabel
                          control={
                            <Radio
                              checked={event.renewReminder === false}
                              name="renewReminder"
                              value="no"
                              onChange={() => setEvent(prev => {
                                return update(prev, {
                                  renewReminder: { $set: false }
                                })
                              })}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          style={{ color: '#797979' }}
                          label="No"
                        />
                      </div>
                    </div>
                    <HelperText error={errors.renewReminder.hasError} helperText={errors.renewReminder.message} />
                  </div>
                  <div style={{ height: '0px', flex: 1, minWidth: '170px', margin: '0px 5px' }} />
                </div>
              )}
              {dateTimeChanged ?
                <div style={{ margin: '10px 5px 0px 5px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  <Button
                    style={{ margin: '0px' }}
                    color='primary'
                    title='Continue'
                    onClick={(e) => {
                      e.preventDefault()
                      submitInitialForm()
                    }}
                  >
                    Continue
                  </Button>
                </div>
                :
                <>
                  {initalSubmitLoading ? <Loading color={'blue'} /> :
                    <>
                      <div style={{ margin: '5px' }}>
                        <RoomReservationSelection
                          buildings={selectableBuildings}
                          rooms={selectableRooms}
                          reservations={event.reservations}
                          addRemoveRoom={addRemoveRoom}
                        />
                      </div>
                      <div style={{ margin: '5px' }}>
                        <ResourceReservationSelection
                          resources={formatedResources}
                          reservations={event.reservations}
                          updateResource={updateResource}
                          bigEvent={event.bigEvent}
                        />
                      </div>
                      <div style={{ margin: '5px' }}>
                        {reservationsChanged &&
                          (<div style={{ margin: '10px 5px 0px 0px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            <Button
                              style={{ margin: '0px' }}
                              color='primary'
                              title='Continue'
                              onClick={(e) => {
                                e.preventDefault()
                                confirmReservations()
                              }}
                            >
                              Continue
                            </Button>
                          </div>)}
                      </div>
                    </>
                  }
                </>
              }
            </div>
          }
        </CardBody>
      </Card>
      {(!dateTimeChanged && !reservationsChanged && !loading) && (
        <div
          style={{
            maxWidth: '1000px',
            height: reservationsChanged ? '0px' : '',
            overflow: reservationsChanged ? 'hidden' : ''
          }}>
          <p style={{ margin: '0px' }}>Upcoming Events</p>
          {event.eventEntriesData.map((entry, index) => {
            const updateEventEntryAtIndex = (update) => {
              updateEventData({
                eventEntriesData: { [index]: update }
              })
            }
            const updateEventEntryError = (updateData) => {
              setEventEntryErrors(prev => {
                return update(prev, {
                  [index]: updateData
                })
              })
            }
            const updateEventEntryLoading = (val) => {
              setEventEntryLoading(prev => {
                return update(prev, {
                  [index]: { $set: val }
                })
              })
            }
            return (
              <EventEntryValidation
                key={index}
                eventEntry={entry}
                updateEventEntry={updateEventEntryAtIndex}
                buildings={selectableBuildings}
                campusID={event.campus.id}
                bigEvent={event.bigEvent}
                uneditedEventEntry={uneditedEventEntries[index]}
                eventEntryError={eventEntryErrors[index]}
                updateEventEntryError={updateEventEntryError}
                eventRanges={eventRanges}
                setAcknowledgeWarnings={setAcknowledgeWarnings}
                updateEventEntryLoading={updateEventEntryLoading}
                eventID={event.id}
              />
            )
          })}
          {(eventHasWarning && !eventHasError) && !acknowledgeWarnings && eventNotLoading && (
            <div style={{ textAlign: 'right' }}>
              <Button
                color='warning'
                title='Acknowledge Warnings'
                onClick={(e) => {
                  e.preventDefault()
                  setAcknowledgeWarnings(true)
                }}
              >
                Acknowledge Warnings
              </Button>
            </div>
          )}
          {((eventHasWarning && acknowledgeWarnings) || !eventHasWarning) && eventNotLoading && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
              <div>
                <Button
                  disabled={eventHasError}
                  color='primary'
                  title='Submit'
                  onClick={(e) => {
                    e.preventDefault()
                    submitForm()
                  }}
                >
                  Submit
                </Button>
              </div>
              {eventHasError &&
                (<p style={{ color: '#f44336', fontSize: '12px', fontWeight: 'bold' }}>* Fix errors before submitting</p>

                )}
            </div>
          )}
        </div>
      )}

    </React.Fragment>
  )
}

const CreateEditEvent = (props) => {
  return (
    <React.Suspense fallback={<Loading color='blue' />}>
      <CreateEditEventComp {...props} />
    </React.Suspense>
  )
}

export default CreateEditEvent