import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React from 'react'

const ToggleItemList = ({ label, items, selectedItems, updateItemList }) => {
  return (
    <>
      <div style={{ marginTop: '-11px' }}>
        <div style={{ margin: '-6px 0px 0px 9px' }}>
          <span style={{
            position: 'relative',
            margin: '0px',
            padding: '0px 5px',
            color: '#757575',
            backgroundColor: '#fff',
            zIndex: 2,
            fontSize: '12px'
          }}>
            {label}
          </span>
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          zIndex: 1,
          borderRadius: "4px",
          background: "#fff",
          padding: "6px 12px",
          border: "1px solid #c4c4c4",
          alignItems: 'center',
          marginTop: '-11px',
          justifyContent: 'space-between',
        }}
      >
        {items.map((i, idx) => {
          const itemIdx = selectedItems.indexOf(i)
          return (
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              key={idx}
            >
              <Checkbox
                style={{ padding: '3px 0px' }}
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name={i}
                checked={itemIdx !== -1}
                onChange={() => {

                  if (itemIdx === -1) {
                    updateItemList({ $push: [i] })
                  } else {
                    updateItemList({ $splice: [[itemIdx, 1]] })
                  }
                }}
              />
              <p
                style={{ margin: '0px' }}
              >
                {i}
              </p>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ToggleItemList